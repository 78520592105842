import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomersThunk,
  deleteCustomerThunk,
} from "../../store/thunks/customerThunks";
import { useNavigate } from "react-router-dom";
import _debounce from "lodash/debounce";
import Button from "../../components/common/Button";
import Input from "../../components/common/Input";
import Filter from "../../components/common/Filter";
import Card from "../../components/common/Card";
import Modal from "../../components/common/Modal";
import Alert from "../../components/common/Alert";
import Pagination from "../../components/common/Pagination";
import Loader from "../../components/common/Loader";
import {
  AVAILABLE_ENVIRONMENTS,
  CUSTOMER_FILTER_OPTIONS,
} from "../../constants/customerConfig.constant";

const CustomerListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customers, loading, error, total } = useSelector(
    (state) => state.customer
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const limit = 10; // Items per page

  useEffect(() => {
    dispatch(
      fetchCustomersThunk({ page: currentPage, limit, search: searchTerm })
    );
  }, [dispatch, currentPage, limit, searchTerm]);

  const debouncedSearch = useCallback(
    _debounce((term) => {
      dispatch(fetchCustomersThunk({ page: currentPage, limit, search: term }));
    }, 300),
    [dispatch, currentPage, limit]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const onDelete = (customerId) => {
    setCustomerToDelete(customerId);
    setIsModalOpen(true);
  };

  const confirmDelete = async () => {
    if (customerToDelete) {
      await dispatch(deleteCustomerThunk(customerToDelete));
      dispatch(
        fetchCustomersThunk({ page: currentPage, limit, search: searchTerm })
      );
      setIsModalOpen(false);
      setCustomerToDelete(null);
    }
  };

  const cancelDelete = () => {
    setIsModalOpen(false);
    setCustomerToDelete(null);
  };

  const totalPages = Math.ceil(total / limit);

  return (
    <div className="container mx-auto p-6 bg-background text-text min-h-screen">
      {/* Header Section */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
        <h1 className="text-3xl font-bold mb-4 md:mb-0">Customer Management</h1>
        <div className="w-full md:w-1/3">
          <Input
            name="search"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search by Name or Email"
            className="w-full"
          />
        </div>
      </div>

      {/* Sticky Filter and Add Button */}
      <div className="flex flex-col md:flex-row justify-between items-center sticky top-16 z-20 bg-background py-3 mb-6 shadow-md rounded-md">
        <Filter
          defaultOption="All"
          options={CUSTOMER_FILTER_OPTIONS}
          onSelect={() => {}}
          className="mb-4 md:mb-0"
        />
        <Button variant="primary" onClick={() => navigate("/customers/add")}>
          Add New Customer
        </Button>
      </div>

      {/* Error Message */}
      {error && <Alert type="error" message={error} className="mb-6" />}

      {/* Loading State */}
      {loading ? (
        <div className="flex justify-center items-center py-20">
          <Loader />
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-6">
          {customers.length > 0 ? (
            customers.map((customer) => {
              const addedEnvironments = customer.configurations.map(
                (config) => config.environment
              );
              const canAddMoreEnvironments =
                AVAILABLE_ENVIRONMENTS.length > addedEnvironments.length;

              return (
                <Card
                  key={customer.customerId}
                  className="shadow-lg p-6 bg-glass-light dark:bg-glass-dark rounded-lg"
                >
                  {/* Customer Info */}
                  <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                    <div>
                      <h2 className="text-2xl font-semibold text-primary dark:text-primary-light">
                        {customer.customerName}
                      </h2>
                      <p className="text-sm text-neutral-dark dark:text-neutral-light">
                        <strong>Contact Person:</strong> {customer.name}
                      </p>
                      <p className="text-sm text-neutral-dark dark:text-neutral-light">
                        <strong>Email:</strong> {customer.email}
                      </p>
                      <p className="text-sm text-neutral-dark dark:text-neutral-light">
                        <strong>Phone:</strong> {customer.phoneNumber}
                      </p>
                    </div>
                    <div className="flex space-x-3 mt-4 md:mt-0">
                      <Button
                        variant="secondary"
                        onClick={() =>
                          navigate(`/customers/edit/${customer.customerId}`)
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => onDelete(customer.customerId)}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>

                  {/* Configurations */}
                  <div className="mt-6">
                    <h3 className="text-xl font-semibold mb-4 text-secondary dark:text-secondary-light">
                      Configurations
                    </h3>
                    {customer.configurations.length > 0 ? (
                      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        {customer.configurations.map(
                          ({ environment, config }) => (
                            <Card
                              key={environment}
                              className="p-4 bg-neutral-light dark:bg-neutral-dark rounded-md shadow-sm flex flex-col justify-between"
                            >
                              <div>
                                <h4 className="text-lg font-medium text-text">
                                  {environment.toUpperCase()}
                                </h4>
                                {/* <p className="text-sm mt-2 text-neutral-dark dark:text-neutral-light">
                                  <strong>Mongo URI:</strong>{" "}
                                  {config.mongoConfig.MONGODB_URI}
                                </p>
                                <p className="text-sm mt-2 text-neutral-dark dark:text-neutral-light">
                                  <strong>JWT Secret:</strong>{" "}
                                  {config.jwtConfig.JWT_SECRET}
                                </p> */}
                                <p className="text-sm mt-2 text-neutral-dark dark:text-neutral-light">
                                  <strong>Admin Email:</strong>{" "}
                                  {config.adminConfig.ROOT_ADMIN_EMAIL}
                                </p>
                                {config.twilioConfig.enabled && (
                                  <p className="text-sm mt-2 text-neutral-dark dark:text-neutral-light">
                                    <strong>Twilio App:</strong>{" "}
                                    {config.twilioConfig.APP_NAME}
                                  </p>
                                )}
                                <p className="text-sm mt-2 text-neutral-dark dark:text-neutral-light">
                                  <strong>Storage Provider:</strong>{" "}
                                  {config.mediaConfig.STORAGE_PROVIDER}
                                </p>
                              </div>
                              <Button
                                variant="primary-outline"
                                onClick={() =>
                                  navigate(
                                    `/customers/${customer.customerId}/configurations/edit/${environment}`
                                  )
                                }
                                className="mt-4 w-full"
                              >
                                Edit Configuration
                              </Button>
                            </Card>
                          )
                        )}
                      </div>
                    ) : (
                      <Alert
                        type="warning"
                        message="No configurations found."
                        className="mb-4"
                      />
                    )}

                    {/* Add Configuration Button */}
                    {canAddMoreEnvironments && (
                      <Button
                        variant="primary"
                        onClick={() =>
                          navigate(
                            `/customers/${customer.customerId}/configurations/add`
                          )
                        }
                        className="mt-4"
                      >
                        Add Configuration
                      </Button>
                    )}
                  </div>
                </Card>
              );
            })
          ) : (
            <Alert type="info" message="No customers found." />
          )}
        </div>
      )}

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="mt-8 flex justify-center">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          closeModal={cancelDelete}
          title="Confirm Deletion"
        >
          <p className="text-sm">
            Are you sure you want to delete this customer? This action cannot be
            undone.
          </p>
          <div className="flex justify-end space-x-4 mt-6">
            <Button variant="secondary" onClick={cancelDelete}>
              Cancel
            </Button>
            <Button variant="danger" onClick={confirmDelete}>
              Delete
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CustomerListPage;
