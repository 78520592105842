export const formatAddCustomerPayload = (payload) => {
  // Ensure the image maxFileSize and quality are numbers
  if (payload.configurations.mediaConfig.image) {
    payload.configurations.mediaConfig.image.maxFileSize = parseInt(
      payload.configurations.mediaConfig.image.maxFileSize,
      10
    );
    payload.configurations.mediaConfig.image.quality = parseInt(
      payload.configurations.mediaConfig.image.quality,
      10
    );
  }

  // Ensure the video maxFileSize is a number
  if (payload.configurations.mediaConfig.video) {
    payload.configurations.mediaConfig.video.maxFileSize = parseInt(
      payload.configurations.mediaConfig.video.maxFileSize,
      10
    );
  }

  // Remove empty AWS fields if the storageProvider is Cloudflare
  if (payload.configurations.mediaConfig.storageProvider === "Cloudflare") {
    delete payload.configurations.mediaConfig.aws;
  }

  return payload;
};

// utils/sanitizePayload.js

export const sanitizeConfigurations = (configurations) => {
  const removeIds = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(removeIds);
    } else if (obj !== null && typeof obj === "object") {
      const newObj = {};
      for (const key in obj) {
        if (key !== "_id") {
          newObj[key] = removeIds(obj[key]);
        }
      }
      return newObj;
    }
    return obj;
  };

  return removeIds(configurations);
};
