// src/services/customerConfigService.js

import api from "./api";

const API_URL = "/customer-configs"; // Adjust the API URL accordingly

// Fetch all configurations for a customer
const fetchCustomerConfigs = async (customerId) => {
  const response = await api.get(`${API_URL}/customer/${customerId}`);
  return response.data;
};

// Fetch a specific configuration for a customer and environment
const fetchCustomerConfigByEnvironment = async (customerId, environment) => {
  const response = await api.get(
    `${API_URL}/customer/${customerId}/environment/${environment}`
  );
  return response.data;
};

// Add a new configuration
const addCustomerConfig = async (configurations) => {
  const response = await api.post(`${API_URL}/add`, configurations);
  return response.data;
};

// Update an existing configuration
const updateCustomerConfig = async (
  customerId,
  environment,
  configurations
) => {
  const response = await api.put(
    `${API_URL}/customer/${customerId}/environment/${environment}`,
    { configurations }
  );
  return response.data;
};

// Delete a configuration
const deleteCustomerConfig = async (customerId, environment) => {
  const response = await api.delete(`${API_URL}/remove`, {
    data: { customerId, environment },
  });
  return response.data;
};

// Recharge Twilio balance
const rechargeTwilioBalance = async (customerId, amount) => {
  const response = await api.post(
    `${API_URL}/customer/${customerId}/recharge`,
    { amount }
  );
  return response.data;
};

// Adjust Twilio balance
const adjustTwilioBalance = async (customerId, amount) => {
  const response = await api.post(
    `${API_URL}/customer/${customerId}/adjustBalance`,
    { amount }
  );
  return response.data;
};

export default {
  fetchCustomerConfigs,
  fetchCustomerConfigByEnvironment,
  addCustomerConfig,
  updateCustomerConfig,
  deleteCustomerConfig,
  rechargeTwilioBalance,
  adjustTwilioBalance,
};
