import { createAsyncThunk } from "@reduxjs/toolkit";
import customerService from "../../services/customerService";

// Create a new customer thunk
export const createCustomerThunk = createAsyncThunk(
  "customer/create",
  async (customerData, { rejectWithValue }) => {
    try {
      const response = await customerService.createCustomer(customerData);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Fetch all customers thunk
export const fetchCustomersThunk = createAsyncThunk(
  "customer/fetchAll",
  async ({ page = 1, limit = 10, search = "" }, { rejectWithValue }) => {
    try {
      const response = await customerService.fetchCustomers(
        page,
        limit,
        search
      );
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Fetch a customer by ID thunk
export const fetchCustomerByIdThunk = createAsyncThunk(
  "customer/fetchById",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await customerService.fetchCustomerById(customerId);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Update a customer thunk
export const updateCustomerThunk = createAsyncThunk(
  "customer/update",
  async ({ customerId, customerData }, { rejectWithValue }) => {
    try {
      const response = await customerService.updateCustomer(
        customerId,
        customerData
      );
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Delete a customer thunk
export const deleteCustomerThunk = createAsyncThunk(
  "customer/delete",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await customerService.deleteCustomer(customerId);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);
