import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  adjustTwilioBalanceThunk,
  fetchCustomerConfigByEnvironmentThunk,
} from "../../store/thunks/customerConfigThunks";
import Modal from "../common/Modal";
import Input from "../common/Input";
import Button from "../common/Button";
import { useParams, useNavigate } from "react-router-dom";

const AdjustBalance = () => {
  const dispatch = useDispatch();
  const { customerId, environment } = useParams();
  const navigate = useNavigate();

  // Change amount state to string
  const [amount, setAmount] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleAdjust = () => {
    const numericAmount = Number(amount);
    if (isNaN(numericAmount)) {
      // Handle invalid input
      console.error("Please enter a valid amount.");
      return;
    }

    dispatch(adjustTwilioBalanceThunk({ customerId, amount: numericAmount }))
      .unwrap()
      .then(() => {
        dispatch(
          fetchCustomerConfigByEnvironmentThunk({ customerId, environment })
        );
        closeModal();
      })
      .catch((err) => {
        console.error("Failed to adjust balance:", err);
      });
  };

  return (
    <>
      <Button variant="primary" size="sm" onClick={openModal}>
        Adjust Balance
      </Button>
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        title="Adjust Twilio Balance"
      >
        <Input
          label="Amount (Use negative value to deduct)"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)} // Store as string
        />
        <div className="flex justify-end space-x-2 mt-4">
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAdjust}>
            Adjust
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AdjustBalance;
