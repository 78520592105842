// src/store/configureStore.js
// src/store/configureStore.js
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";

let store;

export const configureAppStore = () => {
  store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  });
  return store;
};

export const getStore = () => {
  if (!store) {
    throw new Error(
      "Store has not been configured. Call configureAppStore() first."
    );
  }
  return store;
};
