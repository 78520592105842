import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCustomerThunk } from "../../store/thunks/customerThunks";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";
import Input from "../../components/common/Input";
import Alert from "../../components/common/Alert";

const AddCustomerPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.customer.loading);
  const error = useSelector((state) => state.customer.error);

  const [formData, setFormData] = useState({
    name: "",
    customerName: "",
    email: "",
    phoneNumber: "",
  });

  const { name, customerName, email, phoneNumber } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const resultAction = await dispatch(createCustomerThunk(formData));

    if (createCustomerThunk.fulfilled.match(resultAction)) {
      navigate("/customers"); // Redirect to customer list page
    }
  };

  return (
    <div className="container mx-auto p-6 bg-background text-text">
      <h1 className="text-3xl font-bold mb-6">Add New Customer</h1>

      {error && <Alert type="error" message={error} />}

      <form onSubmit={onSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Company Name */}
          <div className="w-full">
            <Input
              label="Company Name (customerName)"
              name="customerName"
              value={customerName}
              onChange={onChange}
              required
              className="text-sm"
            />
          </div>

          {/* Contact Person Name */}
          <div className="w-full">
            <Input
              label="Contact Person Name (name)"
              name="name"
              value={name}
              onChange={onChange}
              required
              className="text-sm"
            />
          </div>
        </div>

        {/* Email */}
        <div className="w-full">
          <Input
            label="Email"
            type="email"
            name="email"
            value={email}
            onChange={onChange}
            required
            className="text-sm"
          />
        </div>

        {/* Phone Number */}
        <div className="w-full">
          <Input
            label="Phone Number"
            type="text"
            name="phoneNumber"
            value={phoneNumber}
            onChange={onChange}
            className="text-sm"
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <Button type="submit" variant="primary" disabled={loading}>
            {loading ? "Adding..." : "Add Customer"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddCustomerPage;
