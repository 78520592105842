// src/store/slices/superAdminSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  loginSuperAdminThunk,
  fetchSuperAdminDetailsThunk,
} from "../thunks/superAdminThunks";

const initialState = {
  token: localStorage.getItem("token"), // Initialize token from localStorage
  loading: false,
  error: null,
  superAdminDetails: null,
};

const superAdminSlice = createSlice({
  name: "superAdmin",
  initialState,
  reducers: {
    logoutSuperAdmin: (state) => {
      state.token = null;
      state.superAdminDetails = null;
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
    },
    clearSuperAdminError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle login SuperAdmin
      .addCase(loginSuperAdminThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginSuperAdminThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
      })
      .addCase(loginSuperAdminThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle fetching SuperAdmin details
      .addCase(fetchSuperAdminDetailsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSuperAdminDetailsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.superAdminDetails = action.payload;
      })
      .addCase(fetchSuperAdminDetailsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logoutSuperAdmin, clearSuperAdminError } =
  superAdminSlice.actions;

export default superAdminSlice.reducer;
