import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Loader from "../../components/common/Loader";
import { ROUTE_PATHS } from "../../config/routesConfig";

const ProtectedRoute = () => {
  const { isAuthenticated, loading, error } = useAuth();
  const location = useLocation();

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Loader /> {/* Display a loader while loading */}
      </div>
    );
  }

  if (!isAuthenticated || error) {
    return (
      <Navigate to={ROUTE_PATHS.LOGIN} state={{ from: location }} replace />
    );
  }

  return <Outlet />; // Render the protected route if authenticated
};

export default ProtectedRoute;
