import React, { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Dialog, DialogPanel } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { logoutSuperAdminThunk } from "../../store/thunks/superAdminThunks";
import { menuRoutes as allMenuRoutes } from "../../config/routesConfig";
import {
  ArrowRightOnRectangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Header from "./Header";
import Button from "../common/Button";

const HorizontalLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const closeMenu = () => setIsOpen(false);

  const handleLogout = () => {
    dispatch(logoutSuperAdminThunk());
  };

  const getLinkClass = (path) => {
    return location.pathname === path ? "bg-primary-dark bg-opacity-20" : "";
  };

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <header className="sticky top-0 z-10 bg-primary">
        <Header
          toggleMenu={() => setIsOpen(!isOpen)}
          menuRoutes={allMenuRoutes}
          getLinkClass={getLinkClass}
        />
      </header>

      <div className="relative">
        <Dialog
          as="div"
          className="relative z-10 md:hidden"
          open={isOpen}
          onClose={closeMenu}
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md" />
          <div className="fixed inset-0 flex z-40">
            <DialogPanel className="relative flex w-full max-w-xs flex-col bg-primary p-6 text-text-on-primary rounded-r-lg shadow-lg">
              <button
                onClick={closeMenu}
                className="self-end focus:outline-none"
              >
                <XMarkIcon className="w-6 h-6" />
              </button>
              <div className="p-6 text-center">
                <h1 className="text-3xl font-extrabold">Dashboard</h1>
              </div>
              <nav className="flex-1 overflow-y-auto scrollbar-thin">
                <ul>
                  {allMenuRoutes.map((route) => (
                    <li key={route.path} className="p-2">
                      <Link
                        to={route.path}
                        className={`hover:border-text-on-primary border-2 border-transparent rounded-lg block p-4 transition duration-200 ${getLinkClass(route.path)}`}
                        onClick={closeMenu}
                      >
                        {route.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
              <Button
                className="flex w-full items-center bg-danger text-white rounded-lg p-2"
                onClick={handleLogout}
                variant="danger"
                icon={<ArrowRightOnRectangleIcon className="w-6 h-6" />}
                size="sm"
              >
                Logout
              </Button>
            </DialogPanel>
          </div>
        </Dialog>
      </div>

      <main className="flex-1 overflow-y-auto bg-background scrollbar-thin">
        <Outlet /> {/* Render child routes here */}
      </main>
    </div>
  );
};

export default HorizontalLayout;
