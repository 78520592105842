import React from "react";
import Input from "../../../components/common/Input";

const AdminConfigForm = ({ configData, onChange, errors = {} }) => {
  return (
    <div className="p-2 border rounded bg-neutral-light dark:bg-neutral-dark mb-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <Input
          label="Admin Email"
          type="email"
          name="adminConfig.ROOT_ADMIN_EMAIL"
          value={configData.ROOT_ADMIN_EMAIL || ""}
          onChange={(e) =>
            onChange("adminConfig", "ROOT_ADMIN_EMAIL", e.target.value)
          }
          required
          className="text-sm"
          error={errors.ROOT_ADMIN_EMAIL}
        />
        <Input
          label="Admin Password"
          type="password"
          name="adminConfig.ROOT_ADMIN_PASSWORD"
          value={configData.ROOT_ADMIN_PASSWORD || ""}
          onChange={(e) =>
            onChange("adminConfig", "ROOT_ADMIN_PASSWORD", e.target.value)
          }
          required
          className="text-sm"
          error={errors.ROOT_ADMIN_PASSWORD}
        />
      </div>
    </div>
  );
};

export default AdminConfigForm;
