import api from "./api";

// Existing login method
const login = async (credentials) => {
  const response = await api.post("/superadmin/login", credentials);
  return response.data;
};

// New method to refresh auth token using refresh token
const getAuthToken = async (refreshToken) => {
  try {
    console.log("Attempting to refresh token with:", refreshToken);

    const response = await api.post("/superadmin/refresh-token", {
      refreshToken,
    });

    console.log("New tokens received:", response);

    // Check if response data is present
    if (response && response.data) {
      console.log("Response data:", response.data);
      return response.data;
    } else {
      console.error("No response data received.");
      throw new Error("No data in response.");
    }
  } catch (error) {
    console.error("Error in getAuthToken:", error.message);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

// Logout method to clear tokens and optionally call a backend logout API
const logout = async () => {
  try {
    // Optionally, you could call a backend logout route here
    // await api.post("/superadmin/logout");

    // Clear the tokens stored in localStorage (or sessionStorage, if used)
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");

    // Optionally, redirect the user to the login page or clear other data
  } catch (error) {
    console.error("Error during logout:", error);
  }
};

const getSuperAdminDetails = async () => {
  const response = await api.get("/superadmin/me");
  return response.data;
};

const superAdminService = {
  login,
  getAuthToken, // Add the new token refresh method
  logout, // Add the logout method
  getSuperAdminDetails,
};

export default superAdminService;
