import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCustomerConfigThunk } from "../../store/thunks/customerConfigThunks";
import MongoConfigForm from "./ConfigForms/MongoConfigForm";
import JWTConfigForm from "./ConfigForms/JWTConfigForm";
import AdminConfigForm from "./ConfigForms/AdminConfigForm";
import TwilioConfigForm from "./ConfigForms/TwilioConfigForm";
import MediaConfigForm from "./ConfigForms/MediaConfigForm";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/common/Button";
import Input from "../../components/common/Input";
import Alert from "../../components/common/Alert";
import { formatAddCustomerPayload } from "../../utils/customerConfigUtils";

const mockForTesting = (customerId) => ({
  customerId,
  environment: "production",
  configurations: {
    mongoConfig: {
      MONGODB_URI:
        "mongodb+srv://shreeratn:Ic08RoCnzrrJSb1Z@cluster0.xrcee.mongodb.net/demo-ratn?retryWrites=true&w=majority&appName=Cluster0",
    },
    jwtConfig: {
      JWT_SECRET: "your_jwt_secret",
      JWT_REFRESH_SECRET: "your_jwt_refresh_secret",
    },
    adminConfig: {
      ROOT_ADMIN_EMAIL: "prem@gmail.com",
      ROOT_ADMIN_PASSWORD: "123123123",
    },
    twilioConfig: {
      enabled: true,
      TWILIO_ACCOUNT_SID: "123",
      TWILIO_AUTH_TOKEN: "123",
      APP_NAME: "Ratn",
    },
    mediaConfig: {
      STORAGE_PROVIDER: "CLOUDFLARE",

      CLOUDFLARE: {
        CLOUDFLARE_DOMAIN: "pub-51673b784cc047f5a05571d523927711.r2.dev",
        CLOUDFLARE_ACCOUNT_ID: "e975112dc7c5d3023b94c38a57127976",
        CLOUDFLARE_ACCESS_KEY_ID: "c3d6a02349e494312117ff1857d9f341",
        CLOUDFLARE_SECRET_ACCESS_KEY:
          "32691b5ef5b55cb2d31905bd37b066a093b9bbd8b20709d50fe34b3f9008a6ab",
        CLOUDFLARE_BUCKET_NAME: "sr-test",
        CLOUDFLARE_API_TOKEN: "X0FV4Y3zw8R5_tobFWSZ3YzqlNhvC3a135zHod5M",
      },
      IMAGE_CONTAINERS: {
        product: "products-images",
        other: "other-images",
      },
      VIDEO_CONTAINERS: {
        product: "product-videos",
        other: "other-videos",
      },
      image: {
        maxFileSize: "2000",
        resize: false,
        quality: "80",
      },
      video: {
        maxFileSize: "4000",
      },
    },
  },
});

const AddCustomerConfigPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { loading, error } = useSelector((state) => state.customerConfig);

  const [formData, setFormData] = useState({
    customerId,
    environment: "development", // default value
    configurations: {
      mongoConfig: {
        MONGODB_URI: "",
        usage: { storage: 0 },
        limits: { storage: 104857600 }, // Default 100MB
      },
      jwtConfig: { JWT_SECRET: "", JWT_REFRESH_SECRET: "" },
      adminConfig: { ROOT_ADMIN_EMAIL: "", ROOT_ADMIN_PASSWORD: "" },
      twilioConfig: {
        enabled: false,
        TWILIO_ACCOUNT_SID: "",
        TWILIO_AUTH_TOKEN: "",
        APP_NAME: "",
        TWILIO_MESSAGING_SERVICE_SID: "",
        TWILIO_SERVICE_CHARGE_PER_SMS: "",
        TWILIO_PER_SMS_COST: "",
        priceUnit: "", // Add priceUnit here
      },
      mediaConfig: {
        STORAGE_PROVIDER: "CLOUDFLARE", // Default value
        AWS: {
          AWS_REGION: "",
          AWS_ACCESS_KEY_ID: "",
          AWS_SECRET_ACCESS_KEY: "",
          AWS_S3_BUCKET_NAME: "",
        },
        CLOUDFLARE: {
          CLOUDFLARE_DOMAIN: "",
          CLOUDFLARE_ACCOUNT_ID: "",
          CLOUDFLARE_ACCESS_KEY_ID: "",
          CLOUDFLARE_SECRET_ACCESS_KEY: "",
          CLOUDFLARE_BUCKET_NAME: "",
          CLOUDFLARE_API_TOKEN: "",
        },
        IMAGE_CONTAINERS: {
          product: "",
          other: "",
        },
        VIDEO_CONTAINERS: {
          product: "",
          other: "",
        },
        image: {
          maxFileSize: "",
          resize: false,
          quality: "",
        },
        video: {
          maxFileSize: "",
        },
        usage: { storage: 0, count: 0 },
        limits: { storage: 524288000, count: 10000 }, // Default values
      },
    },
  });

  // Toggle states for each configuration form
  const [isMongoOpen, setIsMongoOpen] = useState(false);
  const [isJWTOpen, setIsJWTOpen] = useState(false);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [isTwilioOpen, setIsTwilioOpen] = useState(false);
  const [isMediaOpen, setIsMediaOpen] = useState(false);

  // Validation errors
  const [validationErrors, setValidationErrors] = useState({});

  // Handle input changes for main form fields
  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Handle changes within configuration forms
  const handleConfigChange = (configName, key, value) => {
    setFormData((prev) => ({
      ...prev,
      configurations: {
        ...prev.configurations,
        [configName]: {
          ...prev.configurations[configName],
          [key]: value,
        },
      },
    }));
  };

  // Handle changes for nested configurations (e.g., aws within mediaConfig)
  const handleNestedConfigChange = (configName, ...args) => {
    const value = args.pop(); // Last argument is the new value
    const keys = args; // Remaining arguments are the nested keys

    setFormData((prev) => {
      const updatedConfig = { ...prev.configurations[configName] };
      let currentLevel = updatedConfig;

      for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];

        // Clone the nested object at each level
        currentLevel[key] = currentLevel[key] ? { ...currentLevel[key] } : {};
        currentLevel = currentLevel[key];
      }

      // Set the new value
      currentLevel[keys[keys.length - 1]] = value;

      return {
        ...prev,
        configurations: {
          ...prev.configurations,
          [configName]: updatedConfig,
        },
      };
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Client-side validation
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    dispatch(addCustomerConfigThunk(formatAddCustomerPayload(formData)))
      .unwrap()
      .then(() => {
        navigate("/customers");
      })
      .catch((err) => {
        console.error("Failed to add customer config:", err);
      });
  };

  useEffect(() => {
    setFormData(mockForTesting(customerId));
  }, []);

  // Validation logic
  const validateForm = () => {
    const errors = {};

    // MongoConfig validation
    if (!formData.configurations.mongoConfig.MONGODB_URI) {
      errors.mongoConfig = { MONGODB_URI: "MongoDB URI is required." };
    }

    // JWTConfig validation
    if (!formData.configurations.jwtConfig.JWT_SECRET) {
      errors.jwtConfig = { JWT_SECRET: "JWT Secret is required." };
    }
    if (!formData.configurations.jwtConfig.JWT_REFRESH_SECRET) {
      errors.jwtConfig = {
        ...errors.jwtConfig,
        JWT_REFRESH_SECRET: "JWT Refresh Secret is required.",
      };
    }

    // AdminConfig validation
    if (!formData.configurations.adminConfig.ROOT_ADMIN_EMAIL) {
      errors.adminConfig = { ROOT_ADMIN_EMAIL: "Admin Email is required." };
    }
    if (!formData.configurations.adminConfig.ROOT_ADMIN_PASSWORD) {
      errors.adminConfig = {
        ...errors.adminConfig,
        ROOT_ADMIN_PASSWORD: "Admin Password is required.",
      };
    }

    // TwilioConfig validation (only if enabled)
    if (formData.configurations.twilioConfig.enabled) {
      const twilioErrors = {};
      if (!formData.configurations.twilioConfig.TWILIO_ACCOUNT_SID) {
        twilioErrors.TWILIO_ACCOUNT_SID = "Twilio Account SID is required.";
      }
      if (!formData.configurations.twilioConfig.TWILIO_AUTH_TOKEN) {
        twilioErrors.TWILIO_AUTH_TOKEN = "Twilio Auth Token is required.";
      }
      if (!formData.configurations.twilioConfig.APP_NAME) {
        twilioErrors.APP_NAME = "App Name is required.";
      }
      if (!formData.configurations.twilioConfig.TWILIO_MESSAGING_SERVICE_SID) {
        twilioErrors.TWILIO_MESSAGING_SERVICE_SID =
          "Twilio Messaging Service SID is required.";
      }
      if (Object.keys(twilioErrors).length > 0) {
        errors.twilioConfig = twilioErrors;
      }
    }

    // MediaConfig validation
    if (formData.configurations.mediaConfig.STORAGE_PROVIDER === "AWS") {
      const aws = formData.configurations.mediaConfig.AWS;
      const awsErrors = {};
      if (!aws.AWS_REGION) {
        awsErrors.AWS_REGION = "AWS Region is required.";
      }
      if (!aws.AWS_ACCESS_KEY_ID) {
        awsErrors.AWS_ACCESS_KEY_ID = "AWS Access Key ID is required.";
      }
      if (!aws.AWS_SECRET_ACCESS_KEY) {
        awsErrors.AWS_SECRET_ACCESS_KEY = "AWS Secret Access Key is required.";
      }
      if (!aws.AWS_S3_BUCKET_NAME) {
        awsErrors.AWS_S3_BUCKET_NAME = "AWS S3 Bucket Name is required.";
      }
      if (Object.keys(awsErrors).length > 0) {
        errors.mediaConfig = { AWS: awsErrors };
      }
    } else if (
      formData.configurations.mediaConfig.STORAGE_PROVIDER === "CLOUDFLARE"
    ) {
      const cfConfig = formData.configurations.mediaConfig.CLOUDFLARE;
      const cfErrors = {};
      if (!cfConfig.CLOUDFLARE_ACCOUNT_ID) {
        cfErrors.CLOUDFLARE_ACCOUNT_ID = "Cloudflare Account ID is required.";
      }
      if (!cfConfig.CLOUDFLARE_ACCESS_KEY_ID) {
        cfErrors.CLOUDFLARE_ACCESS_KEY_ID =
          "Cloudflare Access Key ID is required.";
      }
      if (!cfConfig.CLOUDFLARE_SECRET_ACCESS_KEY) {
        cfErrors.CLOUDFLARE_SECRET_ACCESS_KEY =
          "Cloudflare Secret Access Key is required.";
      }
      if (!cfConfig.CLOUDFLARE_BUCKET_NAME) {
        cfErrors.CLOUDFLARE_BUCKET_NAME = "Cloudflare Bucket Name is required.";
      }
      if (!cfConfig.CLOUDFLARE_API_TOKEN) {
        cfErrors.CLOUDFLARE_API_TOKEN = "Cloudflare API Token is required.";
      }
      if (Object.keys(cfErrors).length > 0) {
        errors.mediaConfig = { CLOUDFLARE: cfErrors };
      }
    }

    // Image and Video Containers validation
    const imageContainers =
      formData.configurations.mediaConfig.IMAGE_CONTAINERS;
    const videoContainers =
      formData.configurations.mediaConfig.VIDEO_CONTAINERS;
    const containersErrors = {};
    if (!imageContainers.product) {
      containersErrors.imageProduct = "Image Product Container is required.";
    }
    if (!imageContainers.other) {
      containersErrors.imageOther = "Image Other Container is required.";
    }
    if (!videoContainers.product) {
      containersErrors.videoProduct = "Video Product Container is required.";
    }
    if (!videoContainers.other) {
      containersErrors.videoOther = "Video Other Container is required.";
    }

    const imageSettings = formData.configurations.mediaConfig.image;
    const imageErrors = {};

    if (!imageSettings.maxFileSize) {
      imageErrors.maxFileSize = "Max file size is required.";
    }

    if (imageSettings.resize === false) {
      // No need to validate width and height if resize is false
    } else if (typeof imageSettings.resize === "object") {
      if (!imageSettings.resize.width) {
        imageErrors.resize = {
          ...imageErrors.resize,
          width: "Width is required.",
        };
      }
      if (!imageSettings.resize.height) {
        imageErrors.resize = {
          ...imageErrors.resize,
          height: "Height is required.",
        };
      }
    } else {
      imageErrors.resize =
        "Resize must be a boolean or an object with width and height.";
    }

    if (!imageSettings.quality) {
      imageErrors.quality = "Quality is required.";
    }

    if (Object.keys(imageErrors).length > 0) {
      if (!errors.mediaConfig) errors.mediaConfig = {};
      errors.mediaConfig.image = imageErrors;
    }

    if (Object.keys(containersErrors).length > 0) {
      errors.mediaConfig = {
        ...errors.mediaConfig,
        containers: containersErrors,
      };
    }

    return errors;
  };

  return (
    <div className="container mx-auto p-6 bg-background text-text">
      <h2 className="text-2xl font-bold mb-6">Add Customer Configuration</h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Main Form Fields */}
        <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4">
          {/* Customer ID */}
          <div className="w-full md:w-1/2">
            <Input
              label="Customer ID"
              name="customerId"
              value={formData.customerId}
              onChange={(e) => handleInputChange("customerId", e.target.value)}
              required
              disabled
              className="text-sm"
            />
          </div>
          {/* Environment Selection */}
          <div className="w-full md:w-1/2">
            <label className="block text-sm font-medium mb-2">
              Environment
            </label>
            <select
              name="environment"
              value={formData.environment}
              onChange={(e) => handleInputChange("environment", e.target.value)}
              required
              className="w-full p-2 border rounded bg-neutral-light dark:bg-neutral-dark text-text text-sm"
            >
              <option value="development">Development</option>
              <option value="staging">Staging</option>
              <option value="production">Production</option>
            </select>
          </div>
        </div>

        {/* Configurations Section */}
        <div>
          <h3 className="text-xl font-semibold mb-4">Configurations</h3>

          {/* MongoDB Configuration */}
          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <h4 className="text-lg font-semibold">MongoDB Configuration</h4>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsMongoOpen(!isMongoOpen)}
              >
                {isMongoOpen ? "Close" : "Open"}
              </Button>
            </div>
            {isMongoOpen && (
              <MongoConfigForm
                configData={formData.configurations.mongoConfig}
                onChange={handleConfigChange}
                onNestedChange={handleNestedConfigChange}
                errors={validationErrors.mongoConfig}
              />
            )}
          </div>

          {/* JWT Configuration */}
          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <h4 className="text-lg font-semibold">JWT Configuration</h4>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsJWTOpen(!isJWTOpen)}
              >
                {isJWTOpen ? "Close" : "Open"}
              </Button>
            </div>
            {isJWTOpen && (
              <JWTConfigForm
                configData={formData.configurations.jwtConfig}
                onChange={handleConfigChange}
                errors={validationErrors.jwtConfig}
              />
            )}
          </div>

          {/* Admin Configuration */}
          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <h4 className="text-lg font-semibold">Admin Configuration</h4>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsAdminOpen(!isAdminOpen)}
              >
                {isAdminOpen ? "Close" : "Open"}
              </Button>
            </div>
            {isAdminOpen && (
              <AdminConfigForm
                configData={formData.configurations.adminConfig}
                onChange={handleConfigChange}
                errors={validationErrors.adminConfig}
              />
            )}
          </div>

          {/* Twilio Configuration */}
          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center space-x-2">
                <h4 className="text-lg font-semibold">Twilio Configuration</h4>
              </div>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsTwilioOpen(!isTwilioOpen)}
              >
                {isTwilioOpen ? "Close" : "Open"}
              </Button>
            </div>
            {isTwilioOpen && (
              <TwilioConfigForm
                configData={formData.configurations.twilioConfig}
                onChange={handleConfigChange}
                errors={validationErrors.twilioConfig}
              />
            )}
          </div>

          {/* Media Configuration */}
          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <h4 className="text-lg font-semibold">Media Configuration</h4>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsMediaOpen(!isMediaOpen)}
              >
                {isMediaOpen ? "Close" : "Open"}
              </Button>
            </div>
            {isMediaOpen && (
              <MediaConfigForm
                configData={formData.configurations.mediaConfig}
                onChange={handleConfigChange}
                onNestedChange={handleNestedConfigChange}
                errors={validationErrors.mediaConfig}
              />
            )}
          </div>
        </div>

        {/* Error Alert */}
        {error && <Alert type="error" message={error} />}
        {Object.keys(validationErrors).length > 0 && (
          <Alert
            type="error"
            message="Please fix the validation errors above."
          />
        )}

        {/* Submit Button */}
        <div className="flex justify-end">
          <Button type="submit" variant="primary" disabled={loading}>
            {loading ? "Adding..." : "Add Configuration"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddCustomerConfigPage;
