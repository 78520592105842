import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSuperAdminDetailsThunk,
  logoutSuperAdminThunk,
} from "../store/thunks/superAdminThunks";

const useAuth = () => {
  const dispatch = useDispatch();
  const { token, superAdminDetails, loading } = useSelector(
    (state) => state.superAdmin
  );
  const initialized = useRef(false); // Track initialization
  const [error, setError] = useState(null); // Add error state

  useEffect(() => {
    const initializeAuth = async () => {
      if (initialized.current || error) return; // Prevent re-initialization and stop if there's an error

      if (token && !superAdminDetails && !loading) {
        try {
          await dispatch(fetchSuperAdminDetailsThunk());
        } catch (err) {
          if (err.message.includes("Network error")) {
            setError("Network error: Please check your connection.");
          } else {
            setError(err.message || "An error occurred");
          }
          await dispatch(logoutSuperAdminThunk()); // Log out the user on error
        }
      }

      initialized.current = true; // Mark as initialized
    };

    if (!initialized.current && !error) {
      initializeAuth();
    }
  }, [token, superAdminDetails, loading, dispatch, error]); // Only run if no error

  return {
    isAuthenticated: !!token && !!superAdminDetails && !loading && !error,
    loading,
    error,
  };
};

export default useAuth;
