import React from "react";
import Input from "../../../components/common/Input";

const MediaConfigForm = ({
  configData,
  onChange,
  onNestedChange,
  errors = {},
}) => {
  const handleStorageProviderChange = (e) => {
    onChange("mediaConfig", "STORAGE_PROVIDER", e.target.value);
  };

  const handleStorageLimitChange = (e) => {
    const mbValue = e.target.value;
    onNestedChange("mediaConfig", "limits", "storage", mbValue);
  };

  return (
    <div className="p-2 border rounded bg-neutral-light dark:bg-neutral-dark mb-4 space-y-4">
      {/* Storage Provider Selection */}
      <div>
        <label className="block text-sm font-medium mb-2">
          Storage Provider
        </label>
        <select
          name="mediaConfig.STORAGE_PROVIDER"
          value={configData.STORAGE_PROVIDER}
          onChange={handleStorageProviderChange}
          required
          className="w-full p-2 border rounded bg-neutral-light dark:bg-neutral-dark text-text text-sm"
        >
          <option value="AWS">AWS</option>
          <option value="CLOUDFLARE">Cloudflare</option>
        </select>
      </div>

      {/* AWS Configuration */}
      {configData.STORAGE_PROVIDER === "AWS" && (
        <div className="space-y-2">
          <h4 className="text-lg font-semibold">AWS Configuration</h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <Input
              label="AWS Region"
              type="text"
              name="mediaConfig.AWS.AWS_REGION"
              value={configData.AWS.AWS_REGION || ""}
              onChange={(e) =>
                onNestedChange(
                  "mediaConfig",
                  "AWS",
                  "AWS_REGION",
                  e.target.value
                )
              }
              required
              className="text-sm"
              error={errors.AWS?.AWS_REGION}
            />
            <Input
              label="AWS Access Key ID"
              type="text"
              name="mediaConfig.AWS.AWS_ACCESS_KEY_ID"
              value={configData.AWS.AWS_ACCESS_KEY_ID || ""}
              onChange={(e) =>
                onNestedChange(
                  "mediaConfig",
                  "AWS",
                  "AWS_ACCESS_KEY_ID",
                  e.target.value
                )
              }
              required
              className="text-sm"
              error={errors.AWS?.AWS_ACCESS_KEY_ID}
            />
            <Input
              label="AWS Secret Access Key"
              type="text"
              name="mediaConfig.AWS.AWS_SECRET_ACCESS_KEY"
              value={configData.AWS.AWS_SECRET_ACCESS_KEY || ""}
              onChange={(e) =>
                onNestedChange(
                  "mediaConfig",
                  "AWS",
                  "AWS_SECRET_ACCESS_KEY",
                  e.target.value
                )
              }
              required
              className="text-sm md:col-span-2"
              error={errors.AWS?.AWS_SECRET_ACCESS_KEY}
            />
            <Input
              label="AWS S3 Bucket Name"
              type="text"
              name="mediaConfig.AWS.AWS_S3_BUCKET_NAME"
              value={configData.AWS.AWS_S3_BUCKET_NAME || ""}
              onChange={(e) =>
                onNestedChange(
                  "mediaConfig",
                  "AWS",
                  "AWS_S3_BUCKET_NAME",
                  e.target.value
                )
              }
              required
              className="text-sm md:col-span-2"
              error={errors.AWS?.AWS_S3_BUCKET_NAME}
            />
          </div>
        </div>
      )}

      {/* Cloudflare Configuration */}
      {configData.STORAGE_PROVIDER === "CLOUDFLARE" && (
        <div className="space-y-2">
          <h4 className="text-lg font-semibold">Cloudflare Configuration</h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <Input
              label="Cloudflare Domain"
              type="text"
              name="mediaConfig.CLOUDFLARE.CLOUDFLARE_DOMAIN"
              value={configData.CLOUDFLARE.CLOUDFLARE_DOMAIN || ""}
              onChange={(e) =>
                onNestedChange(
                  "mediaConfig",
                  "CLOUDFLARE",
                  "CLOUDFLARE_DOMAIN",
                  e.target.value
                )
              }
              required
              className="text-sm"
              error={errors.CLOUDFLARE?.CLOUDFLARE_DOMAIN}
            />
            <Input
              label="Cloudflare Account ID"
              type="text"
              name="mediaConfig.CLOUDFLARE.CLOUDFLARE_ACCOUNT_ID"
              value={configData.CLOUDFLARE.CLOUDFLARE_ACCOUNT_ID || ""}
              onChange={(e) =>
                onNestedChange(
                  "mediaConfig",
                  "CLOUDFLARE",
                  "CLOUDFLARE_ACCOUNT_ID",
                  e.target.value
                )
              }
              required
              className="text-sm"
              error={errors.CLOUDFLARE?.CLOUDFLARE_ACCOUNT_ID}
            />
            <Input
              label="Cloudflare Access Key ID"
              type="text"
              name="mediaConfig.CLOUDFLARE.CLOUDFLARE_ACCESS_KEY_ID"
              value={configData.CLOUDFLARE.CLOUDFLARE_ACCESS_KEY_ID || ""}
              onChange={(e) =>
                onNestedChange(
                  "mediaConfig",
                  "CLOUDFLARE",
                  "CLOUDFLARE_ACCESS_KEY_ID",
                  e.target.value
                )
              }
              required
              className="text-sm"
              error={errors.CLOUDFLARE?.CLOUDFLARE_ACCESS_KEY_ID}
            />
            <Input
              label="Cloudflare Secret Access Key"
              type="text"
              name="mediaConfig.CLOUDFLARE.CLOUDFLARE_SECRET_ACCESS_KEY"
              value={configData.CLOUDFLARE.CLOUDFLARE_SECRET_ACCESS_KEY || ""}
              onChange={(e) =>
                onNestedChange(
                  "mediaConfig",
                  "CLOUDFLARE",
                  "CLOUDFLARE_SECRET_ACCESS_KEY",
                  e.target.value
                )
              }
              required
              className="text-sm md:col-span-2"
              error={errors.CLOUDFLARE?.CLOUDFLARE_SECRET_ACCESS_KEY}
            />
            <Input
              label="Cloudflare Bucket Name"
              type="text"
              name="mediaConfig.CLOUDFLARE.CLOUDFLARE_BUCKET_NAME"
              value={configData.CLOUDFLARE.CLOUDFLARE_BUCKET_NAME || ""}
              onChange={(e) =>
                onNestedChange(
                  "mediaConfig",
                  "CLOUDFLARE",
                  "CLOUDFLARE_BUCKET_NAME",
                  e.target.value
                )
              }
              required
              className="text-sm"
              error={errors.CLOUDFLARE?.CLOUDFLARE_BUCKET_NAME}
            />
            <Input
              label="Cloudflare API Token"
              type="text"
              name="mediaConfig.CLOUDFLARE.CLOUDFLARE_API_TOKEN"
              value={configData.CLOUDFLARE.CLOUDFLARE_API_TOKEN || ""}
              onChange={(e) =>
                onNestedChange(
                  "mediaConfig",
                  "CLOUDFLARE",
                  "CLOUDFLARE_API_TOKEN",
                  e.target.value
                )
              }
              required
              className="text-sm md:col-span-2"
              error={errors.CLOUDFLARE?.CLOUDFLARE_API_TOKEN}
            />
          </div>
        </div>
      )}

      {/* Image and Video Containers */}
      <div className="space-y-2">
        <h4 className="text-lg font-semibold">Containers</h4>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <Input
            label="Image Product Container"
            type="text"
            name="mediaConfig.IMAGE_CONTAINERS.product"
            value={configData.IMAGE_CONTAINERS.product || ""}
            onChange={(e) =>
              onNestedChange(
                "mediaConfig",
                "IMAGE_CONTAINERS",
                "product",
                e.target.value
              )
            }
            required
            className="text-sm"
            error={errors.IMAGE_CONTAINERS?.product}
          />
          <Input
            label="Image Other Container"
            type="text"
            name="mediaConfig.IMAGE_CONTAINERS.other"
            value={configData.IMAGE_CONTAINERS.other || ""}
            onChange={(e) =>
              onNestedChange(
                "mediaConfig",
                "IMAGE_CONTAINERS",
                "other",
                e.target.value
              )
            }
            required
            className="text-sm"
            error={errors.IMAGE_CONTAINERS?.other}
          />
          <Input
            label="Video Product Container"
            type="text"
            name="mediaConfig.VIDEO_CONTAINERS.product"
            value={configData.VIDEO_CONTAINERS.product || ""}
            onChange={(e) =>
              onNestedChange(
                "mediaConfig",
                "VIDEO_CONTAINERS",
                "product",
                e.target.value
              )
            }
            required
            className="text-sm"
            error={errors.VIDEO_CONTAINERS?.product}
          />
          <Input
            label="Video Other Container"
            type="text"
            name="mediaConfig.VIDEO_CONTAINERS.other"
            value={configData.VIDEO_CONTAINERS.other || ""}
            onChange={(e) =>
              onNestedChange(
                "mediaConfig",
                "VIDEO_CONTAINERS",
                "other",
                e.target.value
              )
            }
            required
            className="text-sm"
            error={errors.VIDEO_CONTAINERS?.other}
          />
        </div>
      </div>

      {/* Image Settings */}
      <div className="space-y-2">
        <h4 className="text-lg font-semibold">Image Settings</h4>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          {/* Max File Size */}
          <Input
            label="Max File Size (KB)"
            type="number"
            name="mediaConfig.image.maxFileSize"
            value={configData.image?.maxFileSize || ""}
            onChange={(e) =>
              onNestedChange(
                "mediaConfig",
                "image",
                "maxFileSize",
                e.target.value
              )
            }
            className="text-sm"
            error={errors.image?.maxFileSize}
          />

          {/* Resize Checkbox */}
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="mediaConfig.image.resize"
              checked={typeof configData.image?.resize === "object"}
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (isChecked) {
                  // Set resize to an object with initial values
                  onNestedChange("mediaConfig", "image", "resize", {
                    width: "",
                    height: "",
                  });
                } else {
                  // Set resize to false
                  onNestedChange("mediaConfig", "image", "resize", false);
                }
              }}
            />
            <span className="ml-2 text-sm">Resize</span>
          </label>

          {/* Conditionally Render Width and Height Inputs */}
          {typeof configData.image?.resize === "object" && (
            <>
              <Input
                label="Width (px)"
                type="number"
                name="mediaConfig.image.resize.width"
                value={configData.image.resize.width || ""}
                onChange={(e) =>
                  onNestedChange(
                    "mediaConfig",
                    "image",
                    "resize",
                    "width",
                    e.target.value
                  )
                }
                className="text-sm"
                error={errors.image?.resize?.width}
              />
              <Input
                label="Height (px)"
                type="number"
                name="mediaConfig.image.resize.height"
                value={configData.image.resize.height || ""}
                onChange={(e) =>
                  onNestedChange(
                    "mediaConfig",
                    "image",
                    "resize",
                    "height",
                    e.target.value
                  )
                }
                className="text-sm"
                error={errors.image?.resize?.height}
              />
            </>
          )}

          {/* Quality */}
          <Input
            label="Quality (%)"
            type="number"
            name="mediaConfig.image.quality"
            value={configData.image?.quality || ""}
            onChange={(e) =>
              onNestedChange("mediaConfig", "image", "quality", e.target.value)
            }
            className="text-sm"
            error={errors.image?.quality}
          />
        </div>
      </div>

      {/* Video Settings */}
      <div className="space-y-2">
        <h4 className="text-lg font-semibold">Video Settings</h4>
        <Input
          label="Max File Size (KB)"
          type="number"
          name="mediaConfig.video.maxFileSize"
          value={configData.video.maxFileSize || ""}
          onChange={(e) =>
            onNestedChange(
              "mediaConfig",
              "video",
              "maxFileSize",
              e.target.value
            )
          }
          className="text-sm"
          error={errors.video?.maxFileSize}
        />
      </div>

      {/* Usage Display */}
      <div className="space-y-2">
        <h4 className="text-lg font-semibold">Usage</h4>
        <p className="text-sm">
          Current Storage Usage: {configData.usage?.storage?.toFixed(4) || 0} MB
        </p>
        <p className="text-sm">
          Current Media Count: {configData.usage?.count || 0}
        </p>
      </div>

      {/* Limits Input */}
      <div className="space-y-2">
        <h4 className="text-lg font-semibold">Limits</h4>
        <Input
          label="Storage Limit (MB)"
          type="number"
          name="mediaConfig.limits.storage"
          value={configData.limits?.storage}
          onChange={handleStorageLimitChange}
          required
          className="text-sm"
          error={errors?.limits?.storage}
        />
        <Input
          label="Media Count Limit"
          type="number"
          name="mediaConfig.limits.count"
          value={configData.limits?.count || ""}
          onChange={(e) =>
            onNestedChange("mediaConfig", "limits", "count", e.target.value)
          }
          required
          className="text-sm"
          error={errors.limits?.count}
        />
      </div>
    </div>
  );
};

export default MediaConfigForm;
