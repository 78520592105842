import api from "./api";

// Create a new customer
const createCustomer = async (customerData) => {
  const response = await api.post("/customers", customerData);
  return response.data;
};

// Fetch all customers
const fetchCustomers = async (page = 1, limit = 10, search = "") => {
  const response = await api.get(
    `/customers?page=${page}&limit=${limit}&search=${search}`
  );
  return response.data;
};

// Fetch a specific customer by customerId
const fetchCustomerById = async (customerId) => {
  const response = await api.get(`/customers/${customerId}`);
  return response.data;
};

// Update a customer
const updateCustomer = async (customerId, customerData) => {
  const response = await api.put(`/customers/${customerId}`, customerData);
  return response.data;
};

// Delete a customer
const deleteCustomer = async (customerId) => {
  const response = await api.delete(`/customers/${customerId}`);
  return response.data;
};

const customerService = {
  createCustomer,
  fetchCustomers,
  fetchCustomerById,
  updateCustomer,
  deleteCustomer,
};

export default customerService;
