import { createAsyncThunk } from "@reduxjs/toolkit";
import superAdminService from "../../services/superAdminService";
import { logoutSuperAdmin } from "../slices/superAdminSlice";

// SuperAdmin login thunk
export const loginSuperAdminThunk = createAsyncThunk(
  "superAdmin/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await superAdminService.login(credentials);

      // Store token and refreshToken in localStorage after successful login
      localStorage.setItem("token", response.token);
      localStorage.setItem("refreshToken", response.refreshToken);

      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Thunk to log out SuperAdmin
export const logoutSuperAdminThunk = createAsyncThunk(
  "superAdmin/logout",
  async (_, { dispatch }) => {
    try {
      // Clear the tokens from localStorage
      await superAdminService.logout();

      // Dispatch the action to clear the SuperAdmin state
      dispatch(logoutSuperAdmin());
    } catch (error) {
      console.error("Error during SuperAdmin logout:", error);
    }
  }
);

// Thunk to fetch SuperAdmin details
export const fetchSuperAdminDetailsThunk = createAsyncThunk(
  "superAdmin/fetchDetails",
  async (_, { rejectWithValue }) => {
    try {
      // Call the service method to fetch SuperAdmin details
      const response = await superAdminService.getSuperAdminDetails();
      return response; // The response data will be available in the fulfilled state
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage); // Return an error in the rejected state
    }
  }
);
