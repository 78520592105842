// src/store/slices/customerConfigSlice.js

import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCustomerConfigsThunk,
  addCustomerConfigThunk,
  fetchCustomerConfigByEnvironmentThunk,
  updateCustomerConfigThunk,
  deleteCustomerConfigThunk,
  rechargeTwilioBalanceThunk,
  adjustTwilioBalanceThunk,
} from "../thunks/customerConfigThunks";

const initialState = {
  configs: [],
  config: null,
  loading: false,
  error: null,
};

const customerConfigSlice = createSlice({
  name: "customerConfig",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all configurations
      .addCase(fetchCustomerConfigsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomerConfigsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.configs = action.payload.configurations || [];
      })
      .addCase(fetchCustomerConfigsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Add configuration
      .addCase(addCustomerConfigThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCustomerConfigThunk.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally, you can push the new config to the configs array
        // state.configs.push(action.payload);
      })
      .addCase(addCustomerConfigThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch configuration by environment
      .addCase(fetchCustomerConfigByEnvironmentThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchCustomerConfigByEnvironmentThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.config = action.payload;
        }
      )
      .addCase(
        fetchCustomerConfigByEnvironmentThunk.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      // Update configuration
      .addCase(updateCustomerConfigThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCustomerConfigThunk.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally, update the config in the configs array
      })
      .addCase(updateCustomerConfigThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete configuration
      .addCase(deleteCustomerConfigThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCustomerConfigThunk.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally, remove the config from the configs array
      })
      .addCase(deleteCustomerConfigThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Recharge Twilio balance
      .addCase(rechargeTwilioBalanceThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(rechargeTwilioBalanceThunk.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally, update the balance in the state
      })
      .addCase(rechargeTwilioBalanceThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Adjust Twilio balance
      .addCase(adjustTwilioBalanceThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(adjustTwilioBalanceThunk.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally, update the balance in the state
      })
      .addCase(adjustTwilioBalanceThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default customerConfigSlice.reducer;
