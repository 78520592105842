import React from "react";
import Input from "../../../components/common/Input";

const JWTConfigForm = ({ configData, onChange, errors = {} }) => {
  return (
    <div className="p-2 border rounded bg-neutral-light dark:bg-neutral-dark mb-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <Input
          label="JWT Secret"
          type="text"
          name="jwtConfig.JWT_SECRET"
          value={configData.JWT_SECRET || ""}
          onChange={(e) => onChange("jwtConfig", "JWT_SECRET", e.target.value)}
          required
          className="text-sm"
          error={errors.JWT_SECRET}
        />
        <Input
          label="JWT Refresh Secret"
          type="text"
          name="jwtConfig.JWT_REFRESH_SECRET"
          value={configData.JWT_REFRESH_SECRET || ""}
          onChange={(e) =>
            onChange("jwtConfig", "JWT_REFRESH_SECRET", e.target.value)
          }
          required
          className="text-sm"
          error={errors.JWT_REFRESH_SECRET}
        />
      </div>
    </div>
  );
};

export default JWTConfigForm;
