import React from "react";
import Input from "../../../components/common/Input";

const MongoConfigForm = ({
  configData,
  onChange,
  onNestedChange,
  errors = {},
}) => {
  const handleStorageLimitChange = (e) => {
    const mbValue = e.target.value;
    onNestedChange("mongoConfig", "limits", "storage", mbValue);
  };

  return (
    <div className="p-2 border rounded bg-neutral-light dark:bg-neutral-dark mb-4 space-y-4">
      {/* MongoDB URI Input */}
      <Input
        label="MongoDB URI"
        type="text"
        name="mongoConfig.MONGODB_URI"
        value={configData.MONGODB_URI || ""}
        onChange={(e) => onChange("mongoConfig", "MONGODB_URI", e.target.value)}
        required
        className="text-sm"
        error={errors.MONGODB_URI}
      />

      {/* Usage Display */}
      <div className="space-y-2">
        <h4 className="text-lg font-semibold">Usage</h4>
        <p className="text-sm">
          Current Storage Usage: {configData.usage?.storage?.toFixed(4) || 0} MB
        </p>
      </div>

      {/* Limits Input */}
      <div className="space-y-2">
        <h4 className="text-lg font-semibold">Limits</h4>
        <Input
          label="Storage Limit (MB)"
          type="number"
          name="mongoConfig.limits.storage"
          value={configData.limits?.storage}
          onChange={handleStorageLimitChange}
          required
          className="text-sm"
          error={errors?.limits?.storage}
        />
      </div>
    </div>
  );
};

export default MongoConfigForm;
