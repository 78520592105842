import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomerByIdThunk,
  updateCustomerThunk,
} from "../../store/thunks/customerThunks";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/common/Button";
import Input from "../../components/common/Input";
import Alert from "../../components/common/Alert";

const EditCustomerPage = () => {
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { customer, loading, error } = useSelector((state) => state.customer);

  const [formData, setFormData] = useState({
    name: "",
    customerName: "",
    email: "",
    phoneNumber: "",
  });

  useEffect(() => {
    dispatch(fetchCustomerByIdThunk(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    if (customer) {
      setFormData({
        name: customer.name || "",
        customerName: customer.customerName || "",
        email: customer.email || "",
        phoneNumber: customer.phoneNumber || "",
      });
    }
  }, [customer]);

  const { name, customerName, email, phoneNumber } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const resultAction = await dispatch(
      updateCustomerThunk({ customerId, customerData: formData })
    );

    if (updateCustomerThunk.fulfilled.match(resultAction)) {
      navigate("/customers"); // Redirect to customer list page
    }
  };

  return (
    <div className="container mx-auto p-6 bg-background text-text">
      <h1 className="text-3xl font-bold mb-6">Edit Customer</h1>

      {loading || !customer ? (
        <p>Loading customer data...</p>
      ) : (
        <form onSubmit={onSubmit} className="space-y-6">
          {error && <Alert type="error" message={error} />}

          {/* Customer Code (readonly) */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">
              Customer Code:
            </label>
            <span className="text-lg font-semibold">
              {customer.customerCode}
            </span>
          </div>

          {/* Company Name */}
          <div className="w-full">
            <Input
              label="Company Name (customerName)"
              name="customerName"
              value={customerName}
              onChange={onChange}
              required
              className="text-sm"
            />
          </div>

          {/* Contact Person Name */}
          <div className="w-full">
            <Input
              label="Contact Person Name (name)"
              name="name"
              value={name}
              onChange={onChange}
              required
              className="text-sm"
            />
          </div>

          {/* Email */}
          <div className="w-full">
            <Input
              label="Email"
              type="email"
              name="email"
              value={email}
              onChange={onChange}
              required
              className="text-sm"
            />
          </div>

          {/* Phone Number */}
          <div className="w-full">
            <Input
              label="Phone Number"
              type="text"
              name="phoneNumber"
              value={phoneNumber}
              onChange={onChange}
              className="text-sm"
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-end">
            <Button type="submit" variant="primary" disabled={loading}>
              {loading ? "Updating..." : "Update Customer"}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditCustomerPage;
