import {
  HomeIcon,
  UserIcon,
  CogIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline"; // Import relevant icons
import NotFound from "../pages/NotFound";
import SuperAdminLogin from "../pages/SuperAdminLogin";
import Layout from "../components/layout/HorizontalLayout";
import ProtectedRoute from "../components/common/ProtectedRoute";
import SuperAdminDashboard from "../pages/SuperAdminDashboard";
import CustomerListPage from "../pages/Customer/CustomerListPage";
import AddCustomerPage from "../pages/Customer/AddCustomerPage";
import EditCustomerPage from "../pages/Customer/EditCustomerPage";
import CustomerConfigListPage from "../pages/CustomerConfig/CustomerConfigListPage";
import AddCustomerConfigPage from "../pages/CustomerConfig/AddCustomerConfigPage";
import EditCustomerConfigPage from "../pages/CustomerConfig/EditCustomerConfigPage";

const ROUTE_PATHS = {
  LOGIN: "/login",
  HOME: "/",
  CUSTOMERS: "/customers",
  ADD_CUSTOMER: "/customers/add",
  EDIT_CUSTOMER: "/customers/edit/:customerId",
  CUSTOMER_CONFIGS: "/customers/:customerId/configurations",
  ADD_CUSTOMER_CONFIG: "/customers/:customerId/configurations/add",
  EDIT_CUSTOMER_CONFIG:
    "/customers/:customerId/configurations/edit/:environment",
  NOT_FOUND: "*",
};

const routes = [
  {
    path: ROUTE_PATHS.LOGIN,
    element: <SuperAdminLogin />,
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        element: <Layout />,
        children: [
          // { path: ROUTE_PATHS.DASHBOARD, element: <SuperAdminDashboard /> },
          { path: ROUTE_PATHS.HOME, element: <CustomerListPage /> },
          { path: ROUTE_PATHS.CUSTOMERS, element: <CustomerListPage /> },
          { path: ROUTE_PATHS.ADD_CUSTOMER, element: <AddCustomerPage /> },
          { path: ROUTE_PATHS.EDIT_CUSTOMER, element: <EditCustomerPage /> },
          // {
          //   path: ROUTE_PATHS.CUSTOMER_CONFIGS,
          //   element: <CustomerConfigListPage />,
          // },
          {
            path: ROUTE_PATHS.ADD_CUSTOMER_CONFIG,
            element: <AddCustomerConfigPage />,
          },
          {
            path: ROUTE_PATHS.EDIT_CUSTOMER_CONFIG,
            element: <EditCustomerConfigPage />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTE_PATHS.NOT_FOUND,
    element: <NotFound />,
  },
];

const menuRoutes = [
  // { path: ROUTE_PATHS.DASHBOARD, name: "Dashboard", icon: HomeIcon },
  { path: ROUTE_PATHS.CUSTOMERS, name: "Customers", icon: UserIcon },
  // { path: ROUTE_PATHS.CUSTOMER_CONFIGS, name: "Configurations", icon: CogIcon },
  // { path: ROUTE_PATHS.ADMIN, name: "SuperAdmin", icon: ShieldCheckIcon },
];

export { routes, ROUTE_PATHS, menuRoutes };
