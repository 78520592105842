import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomerConfigByEnvironmentThunk,
  updateCustomerConfigThunk,
} from "../../store/thunks/customerConfigThunks";
import MongoConfigForm from "./ConfigForms/MongoConfigForm";
import JWTConfigForm from "./ConfigForms/JWTConfigForm";
import AdminConfigForm from "./ConfigForms/AdminConfigForm";
import TwilioConfigForm from "./ConfigForms/TwilioConfigForm";
import MediaConfigForm from "./ConfigForms/MediaConfigForm";
import { useParams, useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";
import Alert from "../../components/common/Alert";
import RechargeBalance from "../../components/UI/RechargeBalance";
import AdjustBalance from "../../components/UI/AdjustBalance";

const EditCustomerConfigPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerId, environment } = useParams();
  const { loading, error } = useSelector((state) => state.customerConfig);

  const [formData, setFormData] = useState(null);
  const [serverErrors, setServerErrors] = useState([]);
  const [isMongoOpen, setIsMongoOpen] = useState(false);
  const [isJWTOpen, setIsJWTOpen] = useState(false);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [isTwilioOpen, setIsTwilioOpen] = useState(false);
  const [isMediaOpen, setIsMediaOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchCustomerConfigByEnvironmentThunk({ customerId, environment }))
      .unwrap()
      .then((data) => {
        setFormData({
          customerId: data.customerId,
          environment: data.environment,
          configurations: {
            mongoConfig: data.configuration.mongoConfig,
            jwtConfig: data.configuration.jwtConfig,
            adminConfig: data.configuration.adminConfig,
            twilioConfig: {
              ...data.configuration.twilioConfig,
              TWILIO_MESSAGING_SERVICE_SID:
                data.configuration.twilioConfig.TWILIO_MESSAGING_SERVICE_SID ||
                "",
              TWILIO_SERVICE_CHARGE_PER_SMS:
                data.configuration.twilioConfig.TWILIO_SERVICE_CHARGE_PER_SMS ||
                "",
              TWILIO_PER_SMS_COST:
                data.configuration.twilioConfig.TWILIO_PER_SMS_COST || "",
              priceUnit:
                data.configuration.twilioConfig.priceUnit || "", // Add priceUnit here
            },
            mediaConfig: data.configuration.mediaConfig,
          },
        });
      })
      .catch((err) => {
        console.error("Failed to fetch config:", err);
      });
  }, [dispatch, customerId, environment]);

  const handleConfigChange = (configName, key, value) => {
    setFormData((prev) => ({
      ...prev,
      configurations: {
        ...prev.configurations,
        [configName]: {
          ...prev.configurations[configName],
          [key]: value,
        },
      },
    }));
  };

  const handleNestedConfigChange = (configName, ...args) => {
    const value = args.pop(); // Last argument is the new value
    const keys = args; // Remaining arguments are the nested keys

    setFormData((prev) => {
      const updatedConfig = { ...prev.configurations[configName] };
      let currentLevel = updatedConfig;

      for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];

        // Clone the nested object at each level
        currentLevel[key] = currentLevel[key] ? { ...currentLevel[key] } : {};
        currentLevel = currentLevel[key];
      }

      // Set the new value
      currentLevel[keys[keys.length - 1]] = value;

      return {
        ...prev,
        configurations: {
          ...prev.configurations,
          [configName]: updatedConfig,
        },
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Submitting form data:", formData);

    dispatch(updateCustomerConfigThunk(formData))
      .unwrap()
      .then(() => {
        navigate("/customers");
      })
      .catch((err) => {
        console.error("Failed to update customer config:", err);
      });
  };

  if (loading || !formData) {
    return (
      <div className="container mx-auto p-6 bg-background text-text">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6 bg-background text-text">
      <h2 className="text-2xl font-bold mb-6">Edit Customer Configuration</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4">
          <div className="w-full md:w-1/2">
            <label className="block text-sm font-medium mb-2">
              Customer ID:
            </label>
            <input
              type="text"
              value={formData.customerId}
              disabled
              className="w-full p-2 border rounded bg-neutral-light dark:bg-neutral-dark text-text"
            />
          </div>
          <div className="w-full md:w-1/2">
            <label className="block text-sm font-medium mb-2">
              Environment:
            </label>
            <input
              type="text"
              value={formData.environment}
              disabled
              className="w-full p-2 border rounded bg-neutral-light dark:bg-neutral-dark text-text"
            />
          </div>
        </div>

        <div>
          <h3 className="text-xl font-semibold mb-4">Configurations</h3>

          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <h4 className="text-lg font-semibold">MongoDB Configuration</h4>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsMongoOpen(!isMongoOpen)}
              >
                {isMongoOpen ? "Close" : "Open"}
              </Button>
            </div>
            {isMongoOpen && (
              <MongoConfigForm
                configData={formData.configurations.mongoConfig}
                onChange={handleConfigChange}
                onNestedChange={handleNestedConfigChange}
                errors={serverErrors.mongoConfig}
              />
            )}
          </div>

          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <h4 className="text-lg font-semibold">JWT Configuration</h4>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsJWTOpen(!isJWTOpen)}
              >
                {isJWTOpen ? "Close" : "Open"}
              </Button>
            </div>
            {isJWTOpen && (
              <JWTConfigForm
                configData={formData.configurations.jwtConfig}
                onChange={handleConfigChange}
                errors={serverErrors.jwtConfig}
              />
            )}
          </div>

          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <h4 className="text-lg font-semibold">Admin Configuration</h4>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsAdminOpen(!isAdminOpen)}
              >
                {isAdminOpen ? "Close" : "Open"}
              </Button>
            </div>
            {isAdminOpen && (
              <AdminConfigForm
                configData={formData.configurations.adminConfig}
                onChange={handleConfigChange}
                errors={serverErrors.adminConfig}
              />
            )}
          </div>

          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <h4 className="text-lg font-semibold">Twilio Configuration</h4>
              <div className="flex space-x-2">
                <RechargeBalance />
                <AdjustBalance />
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setIsTwilioOpen(!isTwilioOpen)}
                >
                  {isTwilioOpen ? "Close" : "Open"}
                </Button>
                {/* Replace buttons with new components */}
              </div>
            </div>
            {isTwilioOpen && (
              <TwilioConfigForm
                configData={formData.configurations.twilioConfig}
                onChange={handleConfigChange}
                errors={serverErrors.twilioConfig}
              />
            )}
          </div>

          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <h4 className="text-lg font-semibold">Media Configuration</h4>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsMediaOpen(!isMediaOpen)}
              >
                {isMediaOpen ? "Close" : "Open"}
              </Button>
            </div>
            {isMediaOpen && (
              <MediaConfigForm
                configData={formData.configurations.mediaConfig}
                onChange={handleConfigChange}
                onNestedChange={handleNestedConfigChange}
                errors={serverErrors.mediaConfig}
              />
            )}
          </div>
        </div>

        {error && <Alert type="error" message={error.message} />}
        {serverErrors.length > 0 && (
          <div className="space-y-2">
            {serverErrors.map((err, index) => (
              <Alert key={index} type="error" message={err.message} />
            ))}
          </div>
        )}

        <div className="flex justify-end">
          <Button type="submit" variant="primary" disabled={loading}>
            {loading ? "Updating..." : "Update Configuration"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditCustomerConfigPage;
