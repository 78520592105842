import React from "react";

const Alert = ({ type = "info", message }) => {
  const typeStyles = {
    info: "bg-blue-100 text-blue-700 border-blue-200",
    success: "bg-green-100 text-green-700 border-green-200",
    warning: "bg-yellow-100 text-yellow-700 border-yellow-200",
    error: "bg-red-100 text-red-700 border-red-200",
  };

  return (
    <div className={`p-4 mb-4 rounded-md border ${typeStyles[type]}`}>
      {message}
    </div>
  );
};

export default Alert;
