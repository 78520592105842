import React from "react";
import Input from "../../../components/common/Input";
import Checkbox from "../../../components/common/Checkbox";
import { formatCurrency } from "../../../utils/formatters";

const TwilioConfigForm = ({ configData, onChange, errors = {} }) => {
  return (
    <div className="p-2 border rounded bg-neutral-light dark:bg-neutral-dark mb-4">
      <div className="flex justify-between items-center mb-2">
        <Checkbox
          label="Enable Twilio Configuration"
          name="twilioConfig.enabled"
          checked={configData.enabled}
          onChange={(e) =>
            onChange("twilioConfig", "enabled", e.target.checked)
          }
          className="text-sm"
        />
      </div>

      {configData.enabled && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <Input
              label="Twilio Account SID"
              type="text"
              name="twilioConfig.TWILIO_ACCOUNT_SID"
              value={configData.TWILIO_ACCOUNT_SID || ""}
              onChange={(e) =>
                onChange("twilioConfig", "TWILIO_ACCOUNT_SID", e.target.value)
              }
              required
              className="text-sm"
              error={errors.TWILIO_ACCOUNT_SID}
            />
            <Input
              label="Twilio Auth Token"
              type="text"
              name="twilioConfig.TWILIO_AUTH_TOKEN"
              value={configData.TWILIO_AUTH_TOKEN || ""}
              onChange={(e) =>
                onChange("twilioConfig", "TWILIO_AUTH_TOKEN", e.target.value)
              }
              required
              className="text-sm"
              error={errors.TWILIO_AUTH_TOKEN}
            />

            <Input
              label="Twilio Messaging Service SID"
              type="text"
              name="twilioConfig.TWILIO_MESSAGING_SERVICE_SID"
              value={configData.TWILIO_MESSAGING_SERVICE_SID || ""}
              onChange={(e) =>
                onChange(
                  "twilioConfig",
                  "TWILIO_MESSAGING_SERVICE_SID",
                  e.target.value
                )
              }
              required
              className="text-sm md:col-span-2"
              error={errors.TWILIO_MESSAGING_SERVICE_SID}
            />

            <Input
              label="App Name (For SMS)"
              type="text"
              name="twilioConfig.APP_NAME"
              value={configData.APP_NAME || ""}
              onChange={(e) =>
                onChange("twilioConfig", "APP_NAME", e.target.value)
              }
              required
              className="text-sm md:col-span-2"
              error={errors.APP_NAME}
            />

            <Input
              label="Twilio Service Charge Per SMS"
              type="number"
              name="twilioConfig.TWILIO_SERVICE_CHARGE_PER_SMS"
              value={configData.TWILIO_SERVICE_CHARGE_PER_SMS}
              onChange={(e) =>
                onChange(
                  "twilioConfig",
                  "TWILIO_SERVICE_CHARGE_PER_SMS",
                  e.target.value
                )
              }
              required
              className="text-sm md:col-span-2"
              error={errors.TWILIO_SERVICE_CHARGE_PER_SMS}
            />

            <Input
              label="Twilio Per SMS Cost (Set By Twilio)"  
              type="number"
              name="twilioConfig.TWILIO_PER_SMS_COST"
              value={configData.TWILIO_PER_SMS_COST}
              onChange={(e) =>
                onChange("twilioConfig", "TWILIO_PER_SMS_COST", e.target.value)
              }
              disabled
              className="text-sm md:col-span-2"
              error={errors.TWILIO_PER_SMS_COST}
            />
            {/* Add the new Price Unit input field */}
            <Input
              label="Price Unit"
              type="text"
              name="twilioConfig.priceUnit"
              value={configData.priceUnit || ""}
              onChange={(e) =>
                onChange("twilioConfig", "priceUnit", e.target.value)
              }
              disabled
              className="text-sm md:col-span-2"
              error={errors.priceUnit}
            />
          </div>
        </>
      )}

      <div className="mt-4">
        <h5 className="text-md font-semibold mb-2">Usage Details</h5>
        <p>
          <strong>Current Balance:</strong>{" "}
          {formatCurrency(configData.usage?.balance || 0)}
        </p>
        <h6 className="text-sm font-semibold mt-2">Recharge History:</h6>
        {configData.usage?.recharges &&
        configData.usage.recharges.length > 0 ? (
          <ul className="list-disc pl-5">
            {configData.usage.recharges.map((recharge, index) => (
              <li key={index}>
                {formatCurrency(recharge.amount)} on{" "}
                {new Date(recharge.date).toLocaleDateString()}
              </li>
            ))}
          </ul>
        ) : (
          <p>No recharge history available.</p>
        )}
      </div>
    </div>
  );
};

export default TwilioConfigForm;
