import { combineReducers } from "@reduxjs/toolkit";
import superAdminSlice from "./slices/superAdminSlice";
import customerConfigSlice from "./slices/customerConfigSlice"; // Import CustomerConfig slice
import customerSlice from "./slices/customerSlice";

const rootReducer = combineReducers({
  superAdmin: superAdminSlice,
  customerConfig: customerConfigSlice, // Add CustomerConfig to the root reducer
  customer: customerSlice,
});

export default rootReducer;
