// src/store/thunks/customerConfigThunks.js

import { createAsyncThunk } from "@reduxjs/toolkit";
import customerConfigService from "../../services/customerConfigService";

// Fetch all configurations for a customer
export const fetchCustomerConfigsThunk = createAsyncThunk(
  "customerConfig/fetchAll",
  async (customerId, { rejectWithValue }) => {
    try {
      const response =
        await customerConfigService.fetchCustomerConfigs(customerId);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Add a new configuration
export const addCustomerConfigThunk = createAsyncThunk(
  "customerConfig/add",
  async (configData, { rejectWithValue }) => {
    try {
      const response =
        await customerConfigService.addCustomerConfig(configData);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Fetch configuration by environment
export const fetchCustomerConfigByEnvironmentThunk = createAsyncThunk(
  "customerConfig/fetchByEnvironment",
  async ({ customerId, environment }, { rejectWithValue }) => {
    try {
      const response =
        await customerConfigService.fetchCustomerConfigByEnvironment(
          customerId,
          environment
        );
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Update a configuration
export const updateCustomerConfigThunk = createAsyncThunk(
  "customerConfig/update",
  async ({ customerId, environment, configurations }, { rejectWithValue }) => {
    try {
      const response = await customerConfigService.updateCustomerConfig(
        customerId,
        environment,
        configurations
      );
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Delete a configuration
export const deleteCustomerConfigThunk = createAsyncThunk(
  "customerConfig/delete",
  async ({ customerId, environment }, { rejectWithValue }) => {
    try {
      const response = await customerConfigService.deleteCustomerConfig(
        customerId,
        environment
      );
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Recharge Twilio balance
export const rechargeTwilioBalanceThunk = createAsyncThunk(
  "customerConfig/rechargeTwilioBalance",
  async ({ customerId, amount }, { rejectWithValue }) => {
    try {
      const response = await customerConfigService.rechargeTwilioBalance(customerId, amount);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Adjust Twilio balance
export const adjustTwilioBalanceThunk = createAsyncThunk(
  "customerConfig/adjustTwilioBalance",
  async ({ customerId, amount }, { rejectWithValue }) => {
    try {
      const response = await customerConfigService.adjustTwilioBalance(customerId, amount);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);
