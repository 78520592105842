import { createSlice } from "@reduxjs/toolkit";
import {
  createCustomerThunk,
  fetchCustomersThunk,
  fetchCustomerByIdThunk,
  updateCustomerThunk,
  deleteCustomerThunk,
} from "../thunks/customerThunks";

const initialState = {
  customers: [],
  customer: null,
  loading: false,
  error: null,
  total: 0,
  page: 1,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    clearCustomerError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create customer
      .addCase(createCustomerThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCustomerThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.customers.push(action.payload.customer);
      })
      .addCase(createCustomerThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch customers
      .addCase(fetchCustomersThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomersThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.customers = action.payload.customers;
        state.total = action.payload.total;
        state.page = action.payload.page;
      })
      .addCase(fetchCustomersThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch customer by ID
      .addCase(fetchCustomerByIdThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomerByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.customer = action.payload;
      })
      .addCase(fetchCustomerByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update customer
      .addCase(updateCustomerThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCustomerThunk.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.customers.findIndex(
          (customer) => customer.customerId === action.payload.customerId
        );
        if (index !== -1) {
          state.customers[index] = action.payload;
        }
      })
      .addCase(updateCustomerThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete customer
      .addCase(deleteCustomerThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCustomerThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.customers = state.customers.filter(
          (customer) => customer.customerId !== action.payload.customerId
        );
      })
      .addCase(deleteCustomerThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearCustomerError } = customerSlice.actions;

export default customerSlice.reducer;
